@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Footer
   ========================================================================== */

// フッター - 全体
.footer {
  background: $colorMain;
  color: #fff;
  padding: 26px 0 48px;
  @include breakpoint('normal-tablet') {
    padding: 4rem 0 1.6rem;
  }
}

// フッター - 内部要素の調整
.footer-inner {
  width: 100%;
  max-width: $innerWidthPc;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  @include breakpoint('normal-tablet') {
    flex-direction: column;
    width: calc(100% - 32px);
    row-gap: 4.8rem;
  }
}

// フッター - コンタクト
.footer-contact {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  @include breakpoint('normal-tablet') {
    order: 3;
    width: 100%;
    padding-top: 4rem;
    border-top: 1px solid #fff;
  }
}

.footer-contact-title {
  margin-bottom: 1.25rem;
}

.footer-contact-num {
  > a {
    font-family: $fontRobot;
    font-weight: 400;
    line-height: 1.3125;
  }
}

.footer-contact-hour {
  font-size: 1.4rem;
}

// フッター - コピーライト
.footer-copy {
  font-size: 1.4rem;
  font-family: $fontRobot;
  font-weight: 400;
  margin-top: auto;
  @include breakpoint('normal-tablet') {
    margin-top: 5rem;
    text-align: center;
  }
}

// フッター - ナビゲーション
.footer-nav {
  @include breakpoint('normal-tablet') {
    order: 1;
  }
  +.footer-nav {
    margin-left: 11%;
  @include breakpoint('normal-tablet') {
    margin-left: 0;
    order: 2;
  }
  }
}

.footer-nav-title {
  font-size: 2rem;
  line-height: 1.3;
  font-family: $fontRobot;
  font-weight: 700;
}

.footer-nav-list {
  @include breakpoint('normal-tablet') {
    padding-top: 0.8rem;
  }
}

.footer-nav-item {
  > a {
    font-size: 1.4rem;
    position: relative;
    padding-left: .5em;
    line-height: 2;

    &:before {
      content: '-';
      position: absolute;
      width: 1em;
      height: 150%;
      line-height: inherit;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}