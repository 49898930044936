@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Fukuriko
   ========================================================================== */

// NP光フクリコページのレイアウト
//
// Fukuriko-box-01
// Fukuriko-contents-box.........Contentsのボックス
// Fukuriko-categories...........各カテゴリ別、利用可能な設備数のボックス
// Fukuriko-services.............サービス詳細のボックス
//

// #Fukuriko-box-01
.fukuriko-box-01 {
  padding: 7.6rem 0 8.3rem;
  .text-01 {
    text-align: center;
    + .fukuriko-contents-box {
      margin-top: 4.1rem;
    }
  }
  .box-wrap {
    @include inner-01;
  }
}

// #Fukuriko-contents-box
.fukuriko-contents-box {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  + .fukuriko-contents-box {
    margin-top: 3.6rem;
  }
}

.fukuriko-title-04 {
  font-size: 2.4rem;
  color: $colorMain;
  text-align: center;
  padding: 2.2rem 0;
  background-color: #E9F6FE;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  @include breakpoint('normal-tablet') {
    font-size: 2rem;
    line-height: 1.2;
  }
}

.fukuriko-list {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint('normal-tablet') {
    padding: 0 6px;
  }
}

.fukuriko-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  figure {
    order: 1;
  }
}

.fukuriko-item-title {
  color: $colorMain;
  font-size: 1.6rem;
  order: 2;
  @include breakpoint('normal-tablet') {
    font-size: 1.8rem;
  }
}

.fukuriko-item-lead {
  font-size: 1.4rem;
  order: 3;
  @include breakpoint('normal-tablet') {
    font-size: 1.5rem;
  }
}

// #Fukuriko-categories
// カテゴリ
.fukuriko-categories {
  padding-bottom: 3.3rem;
  .fukuriko-list {
    max-width: 737px;
    margin: 37px auto 0;
    column-gap: 31px;
    row-gap: 30px;
    @include breakpoint('normal-tablet') {
      margin-top: 22px;
      column-gap: 13px;
      row-gap: 21px;
    }
  }
  .fukuriko-item {
    flex-basis: calc(25% - 23.25px);
    @include breakpoint('normal-tablet') {
      flex-basis: calc(50% - 6.5px);
    }
  }
  .fukuriko-item-title {
    font-size: 1.6rem;
    margin-top: .7rem;
  }
  .fukuriko-item-lead {
    margin-top: .5rem;
  }
}

// #Fukuriko-services
// サービス
.fukuriko-services {
  padding-bottom: 44px;
  .fukuriko-services-wrap {
    display: flex;
    justify-content: center;
    column-gap: 5.714%;
    margin-top: 34px;
    @include breakpoint('normal-tablet') {
      flex-direction: column;
      margin-top: 19px;
      row-gap: 43px;
    }
  }
  .fukuriko-list {
    max-width: 640px;
    column-gap: 26px;
    row-gap: 26px;
    @include breakpoint('normal-tablet') {
      column-gap: 13px;
      row-gap: 21px;
    }
  }
  .fukuriko-item {
    flex-basis: calc(33% - 16px);
    @include breakpoint('normal-tablet') {
      flex-basis: calc(50% - 6.5px);
    }
  }
  .fukuriko-item-title {
    font-size: 1.3rem;
    margin-top: 0.9rem;
  }
  .fukuriko-item-lead {
    margin-top: .8rem;
  }
  .fukuriko-service-otoku {
    max-width: 254px;
    text-align: center;
    @include breakpoint('normal-tablet') {
      max-width: 100%;
      padding: 0 6px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        border: 1px solid #035DBA;
      }
      thead th {
        font-size: 1.3rem;
        color: #fff;
        background-color: #458ACD;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      thead th:nth-child(1) {
        padding-left: 15px;
        text-align: left;
      }
      thead th:nth-child(2) {
        padding-right: 28px;
        text-align: left;
      }
      thead th:nth-child(3) {
        padding-right: 12px;
        text-align: center;
      }
      tbody {
        border-left: 1px solid #458ACD;
        border-right: 1px solid #458ACD;
        border-bottom: 1px solid #458ACD;
      }
      tbody tr {
        td {
          font-size: 1.2rem;
          vertical-align: middle;
          padding: 7px 0;
        }
        &:nth-child(odd) td {
          background-color: #EEEEEE;
        }
        td:nth-child(1) {
          padding-left: 7px;
          text-align: left;
        }
        td:nth-child(2) {
          text-align: center;
          position: relative;
          &:before {
            position: absolute;
            bottom: calc(50% - 2px);
            right: 0;
            content: '';
            width: 13px;
            height: 13px;
            border-top: 2px solid #222;
            border-right: 2px solid #222;
            transform: rotate(45deg) translateY(50%);
          }
        }
        td:nth-child(3) {
          color: #444;
          font-weight: bold;
          text-align: center;
        }
      }
      tfoot {
        border-left: 1px solid #458ACD;
        border-right: 1px solid #458ACD;
        border-bottom: 1px solid #458ACD;
      }
      tfoot td {
        padding-top: 10px;
        padding-bottom: 10px;
        &:nth-child(1) {
          font-size: 1.2rem;
          font-weight: bold;
          text-align: left;
          padding-left: 7px;
        }
        &:nth-child(2) {
          font-size: 1.2rem;
          position: relative;
          text-align: center;
          &:before {
            position: absolute;
            bottom: calc(50% - 2px);
            right: 0;
            content: '';
            width: 13px;
            height: 13px;
            border-top: 2px solid #222;
            border-right: 2px solid #222;
            transform: rotate(45deg) translateY(50%);
          }
        }
        &:nth-child(3) {
          font-size: 1.3rem;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
  .fukuriko-services-conclusion {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 28px;
    padding-top: 10px;
    text-align: center;
    border-bottom: 2px solid $colorAccent;
    display: inline-block;
    background: url('../img/fukuriko/img-arrow-orange.svg') no-repeat top center / 84px 15px;
    .num {
      font-size: 3.2rem;
      color: $colorAccent;
      font-weight: bold;
    }
    .yen {
      color: $colorAccent;
      font-weight: bold;
      font-size:  1.8rem;
    }
  }
}