@use 'Global' as *;

/* ==========================================================================
    #Header
   ========================================================================== */

// ヘッダー - 全体スタイル
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 12px 11px 21px;
  position: fixed;
  width: 100%;
  z-index: 100;

  @include breakpoint("large-tablet") {
    padding: 7px 7px 0 10px;
  }

}

// ヘッダー - ロゴ
.header-logo {
  @include breakpoint("large-tablet") {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

// ヘッダー - メニュー #スマホ時に表示
.header-menu {
  display: none;

  @include breakpoint("large-tablet") {
    display: flex;
    justify-content: center;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    position: relative;
    border: 0;
    background: rgba(0, 93, 186, .85);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: .3s all;

    // メニューボタンの三本線マーク
    span:not(.header-menu-text) {
      width: 24px;
      height: 1px;
      background: #fff;
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      transition: all .3s;

      &:first-child {
        top: 12px;
      }

      &:nth-child(2) {
        top: 20px;
      }

      &:nth-child(3) {
        top: 28px;
      }
    }

    // メニューボタンのテキスト
    .header-menu-text {
      position: absolute;
      font-size: 1.2rem;
      color: #fff;
      font-family: $fontRobot;
      bottom: 2px;
    }
  }
}

// ヘッダー - ナビゲーション
.header-nav {
  margin-left: auto;
  background-color: rgba(255, 255, 255, .9);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 2.5rem;
  padding: 1.2rem 3.8rem;

  @include breakpoint('large-tablet') {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    display: flex;
    margin-top: 44.2px;
  }
}

// ヘッダー - ナビゲーションのリスト
.header-nav-list {
  display: flex;
  align-items: center;
  column-gap: 4.8rem;

  @include breakpoint("large-tablet") {
    display: block;
  }

}

// ヘッダー - ナビゲーションリストの各要素
.header-nav-item {
  @include breakpoint("large-tablet") {
    &:first-child {
      margin-bottom: 2.05rem;

      >a {
        font-size: 2.4rem;
        font-weight: 400;
      }
    }

    &:not(:first-child) {
      border-left: 1px solid rgba(255, 255, 255, .5);
      margin-left: 10.5px;
      padding-top: 5px;

      &:not(:nth-child(2)) {
        padding-top: 3rem;
      }

      >a {
        font-size: 2.2rem;
        font-weight: 300;
        line-height: calc(36 / 22);
        padding-left: 14.5px;
        font-weight: 300;
        position: relative;
        &:before {
          position: absolute;
          font-size: 1.4rem;
          font-weight: bold;
          line-height: calc(24 / 14);
          color: #FFFFFF;
          bottom: calc(100% + 4px);
          right: 0;
          padding-left: 14.5px;
          width: 100%;
          height: 1em;
          content: attr(data-text) "";
        }
      }
    }
  }

  >a {
    color: $colorMain;
    font-weight: bold;

    @include breakpoint("large-tablet") {
      color: #fff;
    }
  }

  // ナビゲーションテキストの間の二つの丸
  &:nth-child(n + 3) {
    > a {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }

      &:before {
        background-color: $colorMain;
        left: -27px;
        @include breakpoint("large-tablet") {
          font-size: 1.4rem;
          font-weight: bold;
          line-height: calc(24 / 14);
          background-color: transparent;
          color: #FFFFFF;
          top: auto;
          bottom: calc(100% + 4px);
          right: 0;
          left: auto;
          border-radius: 0;
          padding-left: 14.5px;
          width: 100%;
          height: 1em;
          transform: none;
          content: attr(data-text) "";
        }
      }

      &:after {
        background-color: $colorSub;
        left: -23px;
        @include breakpoint("large-tablet") {
          content: none;
        }
      }
    }
  }
}

// ヘッダー - スマホ時にボタンを押すとスライドで出てくる要素
.header-toggle-element {
  @include breakpoint("large-tablet") {
    position: fixed;
    background: rgba(0, 93, 186, .95);
    left: 100%;
    top: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    transition: all .3s ease-out;
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-template-rows: max-content min-content min-content;
    padding: 17px 16px 0;
  }
}

// スライドで出てきたメニューの中のロゴ
.header-opened-logo {
  display: none;

  @include breakpoint("large-tablet") {
    display: block;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
}

// スライドで出てきたメニューの中のメニューボタン
.header-opened-menu {
  display: none;

  @include breakpoint("large-tablet") {
    display: block;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 20px;
    height: 20px;
    position: relative;
    border: none;
    background-color: transparent;

    // メニューボタンの×マーク
    span:not(.header-menu-text) {
      width: 24px;
      height: 1px;
      background: #fff;
      position: absolute;
      right: 50%;
      transform: translateX(50%);

      &:first-child {
        top: calc(50%);
        transform: translateX(50%) translateY(-50%) rotate(-45deg);
      }

      &:nth-child(2) {
        top: calc(50%);
        transform: translateX(50%) translateY(-50%) rotate(45deg);
      }
    }
  }

  // メニューボタンのテキスト
  .header-menu-text {
    position: absolute;
    font-size: 1.2rem;
    color: #fff;
    font-family: $fontRobot;
    right: 50%;
    bottom: -100%;
    transform: translateX(50%);
  }
}

// ヘッダー - スマホ時 お問い合わせ
.header-contact {
  display: none;

  @include breakpoint('large-tablet') {
    width: 100%;
    display: block;
    margin: 3.65rem auto 0;
    grid-column: 1/3;
    grid-row: 3/4;
    > a {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      border-radius: 4px;
      padding-left: 68px;
      background: rgba(255, 255, 255, .3) url('../img/common/icon-phone.svg') no-repeat left 14px center / 44px 53px;
    }

    .header-contact-ttl {
      font-size: 2.2rem;
      line-height: calc(24 / 22);
      font-weight: bold;
      @include breakpoint('iPhoneSE') {
        font-size: max(vw(22px, $normal-tablet), 18px);
      }
    }

    .header-contact-hour {
      font-size: 1.4rem;
      line-height: calc(18 / 14);
      margin-top: 2px;
      @include breakpoint('iPhoneSE') {
        font-size: max(vw(14px, $normal-tablet), 12px);
      }
    }
  }
}

// スマホ - ヘッダーメニューを開いた時のスタイル
@include breakpoint('large-tablet') {
  .header.is-opened {
    .header-toggle-element[aria-hidden="false"] {
      // 左へスライドさせる
      left: 0;
    }
  }
}