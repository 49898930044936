@use '../Global' as *;

/* ==========================================================================
    #base
   ========================================================================== */

html {
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
  scroll-padding-top: 72px;

  @include breakpoint('normal-tablet') {
    scroll-padding-top: 51px;
  }
}

body {
  font-family: $fontBase;
  font-size: 1.6rem;
  color: $colorText;
  background-color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

.main {
  position: relative;
}