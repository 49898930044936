@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Others
   ========================================================================== */

// 下記ページのレイアウト
//    サンクスページ
//    404ページ
//    500ページ
// ---------------------------------------
// box-01-others.............1つ目のボックス
//

// #box-01-others
.box-01-others {
  padding: 8rem 0;
  .text-01 {
    text-align: center;
  }
}