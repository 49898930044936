@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Terms
   ========================================================================== */

// 利用規約ページのレイアウト
//
// Terms-box-01................
//
.title-area {
  + .terms-box-01 {
    margin-top: 7.6rem;

    @include breakpoint('normal-tablet') {
      margin-top: 4.8rem;
    }
  }
}

.title-03 + .terms-wrap-01 {
  padding-top: 5.6rem;
}

// #Terms-box-01
.terms-box-01 {
  padding-bottom: 19.8rem;

  @include breakpoint('normal-tablet') {
    padding-bottom: 17.2rem;
  }
}

.terms-wrap-01 {
  @include inner-01;
  +.terms-wrap-01 {
    margin-top: 5.5rem;
    @include breakpoint('normal-tablet') {
      margin-top: 6rem;
    }
  }
}

.terms-title-02 {
  font-size: 2rem;
  width: 100%;
  color: #fff;
  background: #458ACD;
  padding: 14px 16px;
  + .terms-list {
    margin-top: 22px;
  }
}

.terms-list-item {
  margin-left: 3rem;
  position: relative;
  @include breakpoint('normal-tablet') {
    margin-left: 2rem;
  }
  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #005DBA;
    @include breakpoint('normal-tablet') {
      margin-top: .5px;
    }
  }
  + .terms-list-item {
    margin-top: 2rem;
  }
  > a {
    font-size: 1.8rem;
    color: #005DBA;
    text-decoration: underline;
    position: relative;
    @include breakpoint('normal-tablet') {
      font-size: 1.5rem;
    }
    &:before {
      content: '';
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
    }
  }
}