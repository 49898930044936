@use 'Global' as *;

/* ==========================================================================
    #Title
   ========================================================================== */

//
// Title-01...............見出し
// Title-02...............見出し
// Title-03...............見出し
// Title-04...............NP光セキュリティの見出し
// Title-area.............下層ページ見出しエリア
//

// #Title-01
.title-01 {
  text-align: center;
  color: $colorMain;
  font-size: 4rem;

  @include breakpoint('normal-tablet') {
    font-size: 2.8rem;
    line-height: calc(40 / 28);
  }

  @include breakpoint('iPhoneSE') {
    font-size: max(vw(28px, $normal-tablet), 22px);
  }

  .title-01-inner {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;

      @include breakpoint('normal-tablet') {
        width: 12px;
        height: 12px;
      }
    }

    &:before {
      background-color: $colorMain;
      left: -57px;

      @include breakpoint('normal-tablet') {
        left: -19px;
      }
    }

    &:after {
      background-color: $colorSub;
      opacity: .8;
      left: -42px;

      @include breakpoint('normal-tablet') {
        left: -27px;
      }
    }
  }

  .title-01-inner>span {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;

      @include breakpoint('normal-tablet') {
        width: 12px;
        height: 12px;
      }
    }

    &:before {
      background-color: $colorMain;
      right: -57px;

      @include breakpoint('normal-tablet') {
        right: -19px;
      }
    }

    &:after {
      background-color: $colorSub;
      opacity: .8;
      right: -42px;

      @include breakpoint('normal-tablet') {
        right: -27px;
      }
    }
  }
}

// #Title-02
.title-02 {
  text-align: center;
  margin: 7rem 0 3.75rem;

  @include breakpoint('normal-tablet') {
    margin: 3.6rem 0 2.4rem;
    border-top: 1px solid rgb(112, 112, 112, 0.4);
    border-bottom: 1px solid rgb(112, 112, 112, 0.4);
    padding: .7rem 0;
  }

  .title-02-inner {
    font-size: 2.4rem;
    font-weight: bold;
    padding: 12px 0;
    border-top: 1px solid rgb(112, 112, 112, 0.4);
    border-bottom: 1px solid rgb(112, 112, 112, 0.4);

    @include breakpoint('normal-tablet') {
      font-size: 2rem;
      line-height: 1.4;
      padding: 0;
      border: none;
    }

    @include breakpoint('iPhoneSE') {
      font-size: min(vw(20px, $iPhoneSE), 18px);
    }
  }
}

// #Title-03
.title-03 {
  text-align: center;
  position: relative;
  margin-bottom: 2rem;

  @include breakpoint('normal-tablet') {
    padding: 0 5px;
  }

  &:before {
    position: absolute;
    line-height: 1.3;
    font-size: 2rem;
    font-family: $fontRobot;
    color: $colorMain;
    top: -2rem;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    height: 1em;
    content: attr(data-text)"";

    @include breakpoint('normal-tablet') {
      font-size: 1.6rem;
    }
  }

  .title-03-inner {
    font-size: 4rem;
    line-height: 1.45;
    position: relative;
    display: block;

    @include breakpoint('normal-tablet') {
      font-size: 2.8rem;
      line-height: calc(38 / 28);
    }

    // 円装飾
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      bottom: -14px;
      transform: translateX(50%);
    }

    &:before {
      right: calc(50% + 5.5px);
      background: $colorMain;
    }

    &:after {
      right: calc(50% - 5.5px);
      background: $colorSub;
      opacity: .8;
    }
  }

  +.text-01 {
    line-height: 2;
    padding-top: 5.1rem;

    @include breakpoint('normal-tablet') {
      font-size: 1.6rem;
      padding-top: 4.1rem;
    }
  }
}

// #Title-area
.title-area {
  text-align: center;
  width: 100%;
  padding: 10rem 0 3.6rem;
  background: url('../img/common/header-background-img-m.png') no-repeat left top / cover;
  position: relative;
  overflow: hidden;

  @include breakpoint('normal-tablet') {
    padding: 8.7rem 0 8.07rem;
    background-image: url('../img/common/header-background-img-s.png');
  }

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 50vw;
    height: vw(16px, 1440px);
    background: url('../img/common/header-img-yellow.svg') no-repeat right bottom / cover;

    @include breakpoint('normal-tablet') {
      width: 100vw;
      height: vw(8px, 375px);
    }
  }

  .title-area-title {
    font-size: 3.2rem;
    font-weight: bold;
    line-height: calc(46 / 32);
    position: relative;

    @include breakpoint('normal-tablet') {
      font-size: 2.8rem;
      line-height: calc(40 / 28);
    }

    // 円装飾
    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      bottom: -14px;
      transform: translateX(50%);
    }

    &:before {
      right: calc(50% + 6px);
      background: $colorMain;
    }

    &:after {
      right: calc(50% - 6px);
      background: $colorSub;
      opacity: .8;
    }
  }

  .title-area-sub {
    font-size: 1.6rem;
    margin-top: 1.9rem;

    @include breakpoint('normal-tablet') {
      font-size: 1.5rem;
      margin-top: 2.2rem;
    }
  }
}