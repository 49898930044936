@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Top
   ========================================================================== */

// トップページのレイアウト
//
// First-view.............ファーストビューのスタイル。
// Top-about.............NP光コラボレーションとは、のボックスのスタイル。
// Top-plan...........サービスプランのボックススタイル
// Top-area...........提供エリアのボックススタイル
// Top-flow...........ご利用の流れのボックススタイル
// Top-easy-process...........光コラボへの乗り換えは簡単！のボックススタイル
// Top-options...........NP光ならでは！充実のオプションのボックススタイル
//

// #First-view
.first-view {
  position: relative;
  padding-top: 146px;
  padding-left: vw(145px, 1440px);
  padding-bottom: 146px;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    padding-top: vw(226px, 1440px);
    padding-bottom: vw(226px, 1440px);
  }

  @include breakpoint('normal-tablet') {
    padding: 0;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 100vw;
    height: vw(52px, 1440px);
    background: url('../img/top/section-background-01_m.svg') no-repeat right top / 100% 100%;
    z-index: 1;

    @include breakpoint('normal-tablet') {
      background: url('../img/top/section-background-01_s.svg') no-repeat right top / 100% 100%;
      height: vw(40px, 375px);
    }
  }

  .first-view-contents {
    width: 674px;
    max-width: 674px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 1300px) {
      width: vw(674px, 1440px);
    }

    @include breakpoint('normal-tablet') {
      width: 100%;
      max-width: 100%;
      padding-bottom: 49vw;
    }
  }

  .first-view-title {
    margin: 0;
  }

  .first-view-badges {
    padding-top: 26px;
    display: flex;
    column-gap: 16px;
    z-index: 1;

    @media screen and (max-width: 1300px) {
      padding-top: vw(26px, 1440px);
      column-gap: vw(16px, 1440px);
    }

    @include breakpoint('normal-tablet') {
      flex-direction: column;
      row-gap: 6px;
      margin-left: 14px;
      padding-top: 0;
      position: relative;
      top: -2rem;
    }

    .first-view-badges-item {
      width: 138px;

      @media screen and (max-width: 1300px) {
        width: vw(138px, 1440px);
        max-width: 138px;
      }

      @include breakpoint('normal-tablet') {
        width: vw(105px, 375px);
        max-width: unset;
      }

      &:last-of-type {
        @include breakpoint('normal-tablet') {
          position: relative;
          top: -2rem;
        }
      }

      >img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .first-view-human {
    position: absolute;
    right: 0;
    bottom: vw(21px, 1440px);
    width: vw(712px, 1440px);
    max-width: 712px;
    height: auto;
    z-index: 0;

    @include breakpoint('normal-tablet') {
      right: -60px;
      width: 100vw;
      max-width: 100%;
    }
  }

  // ファーストビュー背景画像
  .first-view-background {
    background: url('../img/top/kv-background_m.png') no-repeat left top / cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;

    @include breakpoint('normal-tablet') {
      background: url('../img/top/kv-background_s.png') no-repeat left top / cover;
    }
  }
}

// #Top-about
.top-about {
  padding: 4rem 0 8.4rem;

  @include breakpoint('normal-tablet') {
    padding: 4.5rem 0 5.6rem;
  }

  .text-01 {
    line-height: calc(32 / 18);

    @include breakpoint('normal-tablet') {
      line-height: calc(24 / 15);
    }
  }

  .box-wrap {
    @include inner-01;
  }

  .box-column-02 {
    padding-top: 6.4rem;
    column-gap: 3.2rem;
    position: relative;

    @include breakpoint('normal-tablet') {
      row-gap: 4.4rem;
    }
  }

  .box-column-02-item {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
    border-radius: 6px;

    &:first-child {
      .top-about-title-03 {
        background: rgba(0, 93, 186, .1);
      }

      .top-about-img-flex {
        column-gap: 7.91%;

        @include breakpoint('normal-tablet') {
          column-gap: 3.197%;
        }

        img {
          &:first-child {
            flex-basis: 56.56%;
            max-width: 56.56%;
          }

          &:last-child {
            height: 100%;
            max-height: 143px;
            width: auto;

            @include breakpoint('normal-tablet') {
              height: auto;
            }
          }
        }
      }
    }

    &:last-child {
      .top-about-title-03 {
        background: $colorMain;
        color: #FFE257;
      }

      .top-about-img-flex {
        column-gap: 11.04%;
        padding: 0 1.5rem;

        @include breakpoint('normal-tablet') {
          column-gap: 1.744%;
        }

        img {
          &:first-child {
            flex-basis: 57.91%;
            max-width: 57.91%;
          }

          &:last-child {
            height: 100%;
            max-height: 143px;
            width: auto;


            @include breakpoint('normal-tablet') {
              height: auto;
            }
          }

        }
      }
    }
  }

  .top-about-title-03 {
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: bold;
    font-size: 2.4rem;
    padding: .6rem 0;

    @include breakpoint('normal-tablet') {
      font-size: 1.9rem;
      padding: .5rem 0;
    }
  }

  .top-about-img-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }

  .top-about-arrow {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, 50%);

    @include breakpoint('normal-tablet') {
      top: calc(50% - 2rem);
    }
  }

}

// #Top-plan
.top-plan {
  padding: 10rem 0 14.2rem;
  position: relative;

  @include breakpoint('normal-tablet') {
    padding: 7.6rem 0 9.1rem;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: vw(50px, 1440px);
    background: url('../img/top/section-background-02_m.svg') no-repeat right top / 100% 100%;

    @include breakpoint('normal-tablet') {
      height: vw(36px, math.div($normal-tablet, 2));
      background: url('../img/top/section-background-02_s.svg') no-repeat right top / 100% 100%;
    }
  }

  .box-wrap {
    @include inner-01;
  }

  .box-column-02 {
    +.box-column-02 {
      margin-top: 4rem;
    }
  }

  // タイプ（box-column-02）
  .top-plan-type {
    margin-top: 4.6rem;
    column-gap: 3.2rem;

    // それぞれのボックス
    .box-column-02-item {
      flex: 1;
      border-radius: 6px;
    }

    // タイプタイトル
    .top-plan-ttl-04 {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: $colorAccent2;

      .plan-num {
        color: #444;
        font-size: 1.6rem;
        padding-left: 1.6rem;
        padding-right: 21px;
        border-top-left-radius: 6px;
        font-family: $fontRobot;

        @include breakpoint('normal-tablet') {
          padding-right: 24px;
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(16px, $normal-tablet), 14px);
        }
      }

      .plan-name {
        color: #fff;
        font-size: 2.4rem;
        padding: 6px 0;
        padding-left: 56px;
        height: 100%;
        font-weight: bold;
        background: $colorMain;
        border-top-right-radius: 6px;
        flex: 1;
        position: relative;

        @include breakpoint('normal-tablet') {
          font-size: 2.4rem;
          padding-left: 4px;
          text-align: left;
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(24px, 375px), 22px);
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 50px 20px;
          border-color: transparent transparent #005dba transparent;
        }
      }
    }

    // タイプ内容
    .top-plan-box-column-02-cont {
      background-color: #fff;
      padding-left: 32.5%;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      display: grid;

      justify-content: center;
      grid-template-columns: max-content max-content max-content;

      @include breakpoint('normal-tablet') {
        padding-left: 23.546%;
        padding-top: 7px;
        padding-bottom: 7px;
      }

      .monthly {
        font-weight: bold;
        font-size: 2.4rem;
        color: #444;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        text-align: right;
        padding-right: .4rem;

        @include breakpoint('large-tablet') {
          font-size: vw(24px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          font-size: 2rem;
          line-height: 1.6;
          padding-right: .5rem;
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(20px, $normal-tablet), 16px);
        }
      }

      .num {
        color: $colorAccent;
        font-size: 8rem;
        font-family: $fontRobot;
        font-weight: bold;
        line-height: calc(106 / 80);
        grid-column: 2 / 3;
        grid-row: 1 / 3;

        @include breakpoint('large-tablet') {
          font-size: vw(80px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          font-size: 6.4rem;
          line-height: calc(85 / 64);
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(64px, $normal-tablet), 50px);
        }
      }

      .tax {
        font-size: 1.3rem;
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        align-self: end;

        @include breakpoint('large-tablet') {
          font-size: vw(13px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          font-size: 1.3rem;
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(13px, $normal-tablet), 10px);
        }
      }

      .yen {
        color: $colorAccent;
        font-size: 2.4rem;
        font-weight: bold;
        grid-column: 3 / 4;
        grid-row: 2 / 3;

        @include breakpoint('large-tablet') {
          font-size: vw(24px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          font-size: 2.4rem;
        }

        @include breakpoint('iPhoneSE') {
          font-size: max(vw(24px, $normal-tablet), 18px);
        }
      }
    }

    // ファミリータイプ固有のスタイル
    .type-family {
      .top-plan-box-column-02-cont {
        background: #fff url('../img/top/img-section2-01@2x.png') no-repeat 7.5% center / 17% auto;

        @include breakpoint('large-tablet') {
          background-size: vw(82px, 1440px) vw(73px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          background-position-x: 10px;
          background-size: 63px 62px;
        }

        @include breakpoint('iPhone11') {
          background-size: vw(63px, $iPhone11), vw(62px, $iPhone11);
        }
      }
    }

    // マンションタイプ固有のスタイル
    .type-mansion {
      @include breakpoint('normal-tablet') {
        margin-top: 2.4rem;
      }

      .top-plan-box-column-02-cont {
        background: #fff url('../img/top/img-section2-02@2x.png') no-repeat 7.5% center / 17% auto;

        @include breakpoint('large-tablet') {
          background-size: vw(86px, 1440px) vw(72px, 1440px);
        }

        @include breakpoint('normal-tablet') {
          background-size: 58px 58px;
          background-position-x: 10px;
        }

        @include breakpoint('iPhone11') {
          background-size: vw(58px, $iPhone11), vw(58px, $iPhone11);
        }
      }
    }
  }

  .top-plan-detail {
    column-gap: 3.2rem;

    >.box-column-02-item {
      +.box-column-02-item {
        @include breakpoint('normal-tablet') {
          padding-top: 2.2rem;
        }
      }
    }

    .box-column-02 {
      column-gap: 2.4rem;

      @include breakpoint('normal-tablet') {
        flex-direction: row;
        column-gap: 1.6rem;
      }

      .box-column-02-item {
        &:first-child {
          width: 30.23%;
          flex: unset;
        }

        &:last-child {
          flex: 1;
        }
      }
    }

    .top-plan-title {
      color: $colorMain;
      font-weight: bold;
      font-size: 2rem;
      padding-top: .8rem;
      line-height: 1.2;

      @include breakpoint('normal-tablet') {
        font-size: 1.8rem;
        padding-top: 0;
      }
    }

    .top-plan-text {
      font-size: 1.4rem;
      padding-top: 0.8rem;
      line-height: calc(22 / 14);

      @include breakpoint('normal-tablet') {
        padding-top: .4rem;
        line-height: calc(18 / 14);
      }
    }
  }
}

// #Top-area
.top-area {
  padding: 10.1rem 0 2rem;
  position: relative;

  @include breakpoint('normal-tablet') {
    padding: 3.4rem 0 2rem;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: vw(-50px, 1440px);
    width: 100vw;
    height: vw(51px, 1440px);
    /* safariでの表示ずれ対策で,heightをtopより1px大きくしています */
    background: url('../img/top/section-background-03_m.svg') no-repeat center top / 100% 100%;

    @include breakpoint('normal-tablet') {
      top: vw(-38px, math.div($normal-tablet, 2));
      height: vw(39px, math.div($normal-tablet, 2));
      /* safariでの表示ずれ対策で,heightをtopより1px大きくしています */
      background: url('../img/top/section-background-03_s.svg') no-repeat center top / 100% 100%;
    }
  }

  .box-wrap {
    @include inner-01('short');
    padding-bottom: 13rem;
    position: relative;

    @include breakpoint('normal-tablet') {
      padding-bottom: 3rem;
    }
  }

  .top-area-img-01 {
    img {
      max-width: 100%;
    }
  }

  .title-03 {
    +.text-01 {
      padding-top: 4.2rem;
    }
  }

  .text-01 {
    +.top-area-cont {
      padding-top: 5.2rem;
    }
  }

  .top-area-cont {
    max-width: 570px;
    width: 57.45%;

    @include breakpoint('normal-tablet') {
      max-width: 100%;
      width: 100%;
    }

    +.top-area-cont {
      padding-top: 3.8rem;
    }
  }

  .top-area-ttl-04 {
    font-size: 2rem;
    padding: 2px 0;
    color: #fff;
    font-weight: bold;
    padding-left: 2.2rem;
    background-color: $colorMain;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 34px 20px 0 0;
      border-color: #fff transparent transparent transparent;
      top: 0;
      left: 0;
    }

    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 34px 20px;
      border-color: transparent transparent #fff transparent;
      top: 0;
      right: 0;
    }

    +.top-area-lead {
      margin-top: .7rem;
    }
  }

  .top-area-lead {
    padding-right: 1rem;
    font-size: 1.4rem;
    line-height: calc(22 / 14);
  }

  .top-area-img-01 {
    position: absolute;
    bottom: 0;
    right: vw(-50px, 1440px);
    max-width: 420px;
    width: 45.258%;

    @media screen and (max-width: 1028px) {
      right: 0;
    }

    @include breakpoint('normal-tablet') {
      width: 95%;
      margin: 0 auto;
      position: relative;
      right: auto;
    }
  }
}

// #Top-flow
.top-flow {
  margin-top: 0;
  padding: 10.9rem 0 14rem;
  position: relative;

  @include breakpoint('normal-tablet') {
    padding: 7.6rem 0 9rem;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: vw(60px, 1440px);
    background: url('../img/top/section-background-04_m.svg') no-repeat right top / 100% 100%;

    @include breakpoint('normal-tablet') {
      height: vw(36px, math.div($normal-tablet, 2));
      background: url('../img/top/section-background-04_s.svg') no-repeat right top / 100% 100%;
    }
  }

  .box-wrap {
    @include inner-01('short');
  }

  .box-column-03 {
    padding-top: 5rem;

    @include breakpoint('normal-tablet') {
      row-gap: 3.2rem;
    }
  }

  .box-column-03-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 288px;
    row-gap: 8px;

    @include breakpoint('normal-tablet') {
      max-width: 100%;
    }

    figure {
      order: 1;

      img {
        max-width: 100%;
      }
    }
  }

  .box-column-03-title {
    font-size: 2rem;
    color: $colorMain;
    order: 2;
  }

  .box-column-03-text {
    font-size: 1.4rem;
    order: 3;
  }

}

// #Top-easy-process
.top-easy-process {
  padding: 5rem 0 8rem;
  position: relative;

  @include breakpoint('normal-tablet') {
    padding: vw(41px, math.div($normal-tablet, 2)) 0 5.6rem;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: vw(-62px, 1440px);
    width: 100vw;
    height: vw(63px, 1440px);
    /* safariでの表示ずれ対策で,heightをtopより1px大きくしています */
    background: url('../img/top/section-background-05_m.svg') no-repeat right top / 100% 100%;

    @include breakpoint('normal-tablet') {
      top: vw(-36px, math.div($normal-tablet, 2));
      height: vw(37px, math.div($normal-tablet, 2));
      /* safariでの表示ずれ対策で,heightをtopより1px大きくしています */
      background: url('../img/top/section-background-05_s.svg') no-repeat center top / 100% 100%;
    }
  }

  .box-wrap {
    @include inner-01('short');
  }

  .box-column-02:nth-child(2) {
    padding-top: 4.4rem;
    position: relative;

    @include breakpoint('normal-tablet') {
      row-gap: 1.7rem;
      align-items: center;
    }

    .box-column-02 {
      margin-top: 2rem;
      column-gap: 3.2rem;

      @include breakpoint('normal-tablet') {
        margin-top: 0;
        flex-direction: row;
      }
    }

    .top-easy-process-arrow {
      width: 26%;
      max-width: 241px;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -51%);

      @include breakpoint('normal-tablet') {
        width: 100%;
        max-width: unset;
        position: relative;
        right: auto;
        top: auto;
        transform: none;
      }

      p {
        font-size: 2.4rem;
        text-align: center;
        font-weight: bold;
        margin: 0;
        padding: 0;

        @include breakpoint('normal-tablet') {
          color: $colorAccent;
        }
      }

      picture {
        display: block;
      }

      picture img {
        @include breakpoint('normal-tablet') {
          margin: 0 auto;
        }
      }
    }

    .box-column-02-item {
      max-width: 353px;

      &:first-child {
        .top-easy-process-ttl-04 {
          background-color: rgba(0, 93, 186, .1);
        }
      }

      &:last-child {
        .top-easy-process-ttl-04 {
          color: #fff;
          background-color: $colorMain;
        }
      }
    }

  }

  .top-easy-process-ttl-04 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 2px 0;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      top: 0;
    }

    &:before {
      border-width: 34px 20px 0 0;
      border-color: #fff transparent transparent transparent;
      left: 0;
    }

    &:after {
      border-width: 0 0 34px 20px;
      border-color: transparent transparent #fff transparent;
      right: 0;
    }

    +img {
      margin-top: 2.8rem;
    }
  }

  .top-easy-process-box-text- {

    &01,
    &02,
    &03 {
      color: $colorMain;
      font-weight: bold;
      text-align: center;
      padding: 8px 0;
      background: #ddd;
    }

    &03 {
      background-color: $colorAccent2;
      max-width: 267px;
      margin: 2rem auto 0;

      @include breakpoint('normal-tablet') {
        margin: 0 auto;
      }
    }
  }

  .text-01 {
    padding-top: 3rem;
    line-height: calc(32 / 18);
  }

  /* ===== 以下、タブエリア内 ===== */
  // タブエリア - 注釈
  .text-caution {
    font-size: 1.2rem;

    +p {
      margin-top: .4rem;
    }
  }

  // タブエリア - 連絡先部分
  .top-easy-process-column-01 {
    display: flex;
    width: 100%;
    margin-top: 12px;

    @include breakpoint('normal-tablet') {
      display: block;
    }

    .top-easy-process-column-item {
      flex: 1;
      text-align: center;

      .top-easy-process-column-cont:last-child {
        padding-bottom: 1.7rem;
      }
    }

    // テーブル - 1行目の見出し
    .top-easy-process-column-ttl-01 {
      font-size: 1.8rem;
      font-weight: bold;
      color: #fff;
      padding: .8rem 0;
      background-color: #458ACD;
    }

    // テーブル - 背景グレーの見出し
    .top-easy-process-column-ttl-02 {
      background-color: #EEEEEE;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2;
      border-left: 1px solid #458ACD;
      border-right: 1px solid #458ACD;
    }

    .top-easy-process-column-cont {
      background: #fff;
      border-left: 1px solid #458ACD;
      border-right: 1px solid #458ACD;
      border-bottom: 1px solid #458ACD;
      padding-bottom: .8rem;
    }

    // ボタン
    .top-easy-process-column-button {
      color: #fff;
      background: #005DBA url("../img/common/arrow-right.svg") no-repeat right 21px center/13px 14px;
      width: 69.5%;
      margin-top: .8rem;
      padding: .8rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 4px;
      box-shadow: 0 3px 0 #003369;
      border: 1px solid transparent;
      transition: top .3s ease-out, background-color .3s ease-out, color .3s ease-out;
      position: relative;

      &:hover {
        box-shadow: 0 0 0 #003369;
        background: #E9F6FE url("../img/common/arrow-right-02.svg") no-repeat right 21px center/13px 14px;
        color: #035DBA;
        border: 1px solid #035DBA;
        margin-top: 1.1rem;
        top: 3px;

        +p {
          margin-top: .1rem;
        }
      }

      span {
        display: block;

        &:first-child {
          font-size: 1.6rem;
          line-height: calc(20 / 16);
        }

        &:nth-child(2) {
          font-size: 1.4rem;
          line-height: calc(20 / 14);
        }
      }

      +p {
        font-size: 1.3rem;
        line-height: calc(18 / 13);
        margin-top: .4rem;
      }
    }

    // 電話番号
    .top-easy-process-column-tel {
      font-size: 3.2rem;
      font-family: $fontRobot;
      color: #444444;
      font-weight: bold;
      line-height: calc(43 / 32);
      margin-top: .8rem;
      padding-left: 39px;
      display: inline-block;
      background: url('../img/common/icon_freedial.svg') no-repeat left center / 37px 21px;
    }

    // 受付時間
    .top-easy-process-column-hours {
      font-size: 1.3rem;
      line-height: calc(18 / 13);
    }
  }

}

// #Top-options
.top-options {
  padding: vw(109px, 1440px) 0 18rem;
  position: relative;

  @include breakpoint('normal-tablet') {
    padding: 7.6rem 0 4.3rem;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: vw(62px, 1440px);
    background: url('../img/top/section-background-06_m.svg') no-repeat right top / cover;

    @include breakpoint('normal-tablet') {
      height: vw(38px, math.div($normal-tablet, 2));
      background: url('../img/top/section-background-06_s.svg') no-repeat right top / cover;
    }
  }

  .box-wrap {
    @include inner-01('short');
  }

  .text-01 {
    padding-top: 6.3rem;

    @include breakpoint('normal-tablet') {
      padding-top: 3rem;
    }
  }

  .top-options-img-01 {
    padding-top: 4rem;
  }

  .top-options-card-list {
    display: grid;
    margin-top: 6.5rem;
    column-gap: 3.2rem;
    row-gap: 1.8rem;

    @include breakpoint('normal-tablet') {
      margin-top: 3.2rem;
    }
  }

  .top-options-card-item {

    >.top-options-card-inner {
      display: block;
      border-radius: 5px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
      height: 100%;
      background-color: #fff;
    }

    >a {
      .top-options-card-ttl {
        background: $colorMain url('../img/common/arrow-right.svg') no-repeat right 21px center / 13px 14px;
        transition: background .5s ease-out, color .5s ease-out;
      }

      &:hover {
        .top-options-card-ttl {
          background: #E9F6FE url('../img/common/arrow-right-02.svg') no-repeat right 21px center / 13px 14px;
          color: $colorMain;
        }
      }
    }

    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(5) {
      grid-column: 1 / 2;

      @include breakpoint('normal-tablet') {
        grid-column: 1 / 2;
      }
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) {
      grid-column: 2 / 3;

      @include breakpoint('normal-tablet') {
        grid-column: 1 / 2;
      }
    }

    &:nth-child(-n+7) {
      .top-options-card-column {
        padding-top: 1.3rem;
        padding-bottom: 1.5rem;
        padding-right: 1.8rem;

        @include breakpoint('normal-tablet') {
          padding-right: 1.1rem;
          padding-left: 0;
        }
      }

      .top-options-card-text {
        flex-basis: calc(100% - 10rem);

        @include breakpoint('normal-tablet') {
          flex-basis: calc(100% - 7rem);
        }
      }
    }

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      grid-column: 1 / 3;

      @include breakpoint('normal-tablet') {
        grid-column: 1 / 2;
      }

      .top-options-card-column {
        padding: 1.6rem 3.2rem 1.6rem 0;
        align-items: center;

        @include breakpoint('normal-tablet') {
          align-items: flex-start;
        }

        .top-options-card-dl,
        .top-options-card-text {
          flex-basis: calc(100% - 12rem);

          @include breakpoint('normal-tablet') {
            flex-basis: calc(100% - 7rem);
          }
        }
      }
    }

    &:nth-child(1) {
      .top-options-card-figure img {
        width: 72px;

        @include breakpoint('normal-tablet') {
          width: 61px;
        }
      }
    }

    &:nth-child(2) {
      .top-options-card-figure img {
        width: 67px;

        @include breakpoint('normal-tablet') {
          width: 51px;
        }
      }
    }

    &:nth-child(3) {
      .top-options-card-figure img {
        width: 61px;

        @include breakpoint('normal-tablet') {
          width: 49px;
        }
      }
    }

    &:nth-child(4) {
      .top-options-card-figure img {
        width: 53px;

        @include breakpoint('normal-tablet') {
          width: 51px;
        }
      }
    }

    &:nth-child(5) {
      .top-options-card-figure img {
        width: 83px;

        @include breakpoint('normal-tablet') {
          width: 66px;
        }
      }
    }

    &:nth-child(6) {
      .top-options-card-figure img {
        width: 57px;

        @include breakpoint('normal-tablet') {
          width: 56px;
        }
      }
    }

    &:nth-child(7) {
      .top-options-card-figure img {
        width: 64px;
        margin-left: 22px;

        @include breakpoint('normal-tablet') {
          width: 54px;
          margin-left: auto;
        }
      }
    }

    &:nth-child(8) {
      .top-options-card-figure img {
        width: 81px;

        @include breakpoint('normal-tablet') {
          width: 56px;
        }
      }
    }

    &:nth-child(9) {
      .top-options-card-figure img {
        width: 78px;

        @include breakpoint('normal-tablet') {
          width: 58px;
        }
      }
    }
  }

  // リスト見出し
  .top-options-card-ttl {
    font-size: 2.2rem;
    line-height: calc(24 / 22);
    text-align: center;
    font-weight: bold;
    color: #fff;
    background: $colorMain;
    padding: 7px 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  // リストコンテンツ
  .top-options-card-column {
    font-size: 1.4rem;
    line-height: calc(22 / 14);
    display: flex;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    @include breakpoint('normal-tablet') {
      padding: 1rem;
    }
  }

  // テキスト
  .top-options-card-text {
    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
      line-height: calc(22 / 14);
    }
  }

  // 画像
  .top-options-card-figure {
    flex: 1;

    img {
      height: auto;
      margin: 0 auto;
    }
  }

  .top-options-card-dl {
    overflow: hidden;

    dt,
    dd {
      float: left;
      padding-bottom: 5px;
      margin-bottom: 5px;

      &:not(:last-of-type) {
        border-bottom: 1px dotted #ddd;
      }

      @include breakpoint('normal-tablet') {
        float: none;
      }
    }

    dt {
      font-weight: bold;
      width: 242px;

      &:not(:last-of-type) {
        @include breakpoint('normal-tablet') {
          border-bottom: none;
        }
      }

      @include breakpoint('normal-tablet') {
        width: 100%;
        border-bottom: none;
      }
    }

    dd {
      width: calc(100% - 242px);
      padding-left: 20px;

      @include breakpoint('normal-tablet') {
        padding-left: 0;
        width: 100%;
      }
    }

  }
}