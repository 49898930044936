@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Security
   ========================================================================== */

// NP光セキュリティページのレイアウト
//
// Security-title-01......見出し
// Secutiry-01............1つ目のblock
// Secutiry-02............2つ目のblock
//

// #Security-title-01
.security-title-01 {
  text-align: center;
  margin: 0 auto;

  span {
    font-size: 2.4rem;
    font-weight: bold;
    @include breakpoint('normal-tablet') {
      font-size: 2rem;
      display: block;
    }
  }
}

// #Secutiry-01
.secutiry-01 {
  padding: 7.2rem 0 4.8rem;
  @include breakpoint('normal-tablet') {
    padding: 4.8rem 0;
  }

  .box-wrap {
    @include inner-01;
  }
  
  .security-title-01 {
    @include breakpoint('normal-tablet') {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 0;
        z-index: 1;
      }
      &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100px 46px;
        border-color: transparent transparent #ffffff transparent;
        right: 0;
        top: 0;
      }
      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 100px 46px 0 0;
        border-color:  #ffffff transparent transparent transparent;
        left: 0;
        top: 0;
      }
    }
    span {
      font-size: 2.4rem;
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 0;
        @include breakpoint('normal-tablet') {
          content: none;
        }
      }
      &:first-child {
        color: #fff;
        background-color: $colorMain;
        padding: .8rem;
        padding-left: 40px;
        padding-right: 40px;
        @include breakpoint('large-tablet') {
          padding-right: vw(40px, $large-tablet);
          padding-left: vw(40px, $large-tablet);
        }
        @include breakpoint('normal-tablet') {
          font-size: 2rem;
        }
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 51px 35px 0 0;
          border-color: #fff transparent transparent transparent;
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        background-color: #E9F6FE;
        padding: .8rem 1.6rem;
        padding-right: 40px;
        padding-left: 40px;
        @include breakpoint('large-tablet') {
          padding-right: vw(40px, $large-tablet);
          padding-left: vw(40px, $large-tablet);
        }
        @include breakpoint('normal-tablet') {
          font-size: 1.8rem;
        }
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 51px 35px 0 0;
          border-color: #e9f6fe transparent transparent transparent;
          top: 0;
          left: 100%;
        }
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 51px 35px;
          border-color: transparent transparent #e9f6fe transparent;
          top: 0;
          right: 100%;
        }
      }
    }
  }
}

// #Secutiry-02
.secutiry-02 {
  padding: 6rem 0 12rem;
  @include breakpoint('normal-tablet') {
    padding: 4.8rem 0 5.2rem;
  }

  .box-wrap {
    @include inner-01;
  }

  .security-title-01 {
    position: relative;
    @include breakpoint('normal-tablet') {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 0;
        z-index: 1;
      }
      &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 115px 46px;
        border-color: transparent transparent #E9F6FE transparent;
        right: 0;
        top: 0;
      }
      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 115px 46px 0 0;
        border-color:  #E9F6FE transparent transparent transparent;
        left: 0;
        top: 0;
      }
    }
    span {
      position: relative;
      font-size: 2.4rem;
      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 0;
        @include breakpoint('normal-tablet') {
          content: none;
        }
      }

      &:first-child {
        color: #fff;
        background-color: $colorMain;
        padding: .8rem;
        padding-left: 40px;
        padding-right: 40px;
        @include breakpoint('large-tablet') {
          padding-right: vw(40px, $large-tablet);
          padding-left: vw(40px, $large-tablet);
        }
        @include breakpoint('normal-tablet') {
          font-size: 2rem;
        }
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 51px 35px 0 0;
          border-color: #E9F6FE transparent transparent transparent;
          top: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        background-color: #fff;
        padding: .8rem 1.6rem;
        padding-right: 40px;
        padding-left: 40px;
        @include breakpoint('large-tablet') {
          padding-right: vw(40px, $large-tablet);
          padding-left: vw(40px, $large-tablet);
        }
        @include breakpoint('normal-tablet') {
          font-size: 1.8rem;
        }
        &:before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 51px 35px 0 0;
          border-color: #fff transparent transparent transparent;
          top: 0;
          left: 100%;
        }
        &:after {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 51px 35px;
          border-color: transparent transparent #fff transparent;
          top: 0;
          right: 100%;
        }
      }
    }
  }
}

.security-list {
  margin: 42.5px auto 0;
  max-width: 924px;
  width: 100%;

  .security-list-item {
    display: grid;
    grid-template-columns: 48px 1fr;
    @include breakpoint('normal-tablet') {
      grid-template-columns: 41px 1fr;
    }
    +.security-list-item {
      padding-top: 4.2rem;
    }
  }

  .security-list-lead {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    color: $colorMain;
    font-size: 2.8rem;
    font-weight: bold;
    position: relative;
    margin-left: 54px;
    @include breakpoint('normal-tablet') {
      font-size: 2.4rem;
      align-items: center;
      margin-left: 2.3rem;
    }
  }

  .security-list-description {
    border-top: 1px solid rgba(153, 153, 153, .5);
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-left: 54px;
    line-height: 2;
    @include breakpoint('normal-tablet') {
      grid-column: 1 / 3;
      font-size: 1.5rem;
      padding-top: .45rem;
      margin-top: .9rem;
      margin-left: 0;
      line-height: 1.6;
    }
  }

  .security-list-num {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    line-height: 1;
    font-size: 1.4rem;
    font-weight: bold;
    color: $colorText;
    font-family: $fontRobot;
    position: relative;
    text-align: center;
    @include breakpoint('normal-tablet') {
      grid-row: 1 / 2;
      font-size: 1.2rem;
    }

    &:before {
      position: absolute;
      content: '';
      width: 2px;
      height: 70px;
      background-color: $colorMain;
      top: 0;
      right: -28px;
      transform: rotate(24deg);
      @include breakpoint('normal-tablet') {
        top: auto;
        right: -16px;
        bottom: 7px;
        height: 58px;
        transform: rotate(16deg);
      }
    }

    span {
      font-size: 6.4rem;
      color: $colorMain;
      font-weight: bold;
      display: block;
      @include breakpoint('normal-tablet') {
        font-size: 5.6rem;
      }
    }
  }

  +.security-cautions {
    margin-top: 3.2rem;
  }
}

.security-cautions {
  margin: 0 auto;
  max-width: 924px;
  width: 100%;
  padding-left: 106px;
  @include breakpoint('normal-tablet') {
    padding-left: 0;
  }

  .security-cautions-item {
    font-size: 1.2rem;
    display: flex;
    word-break: break-all;

    .num {
      padding-right: .4em;
      word-break: keep-all;
    }
  }
}