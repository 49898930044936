@use "sass:math";

// ==========================================================================
// #Variables
// ==========================================================================

//
// #color....................色の設定
// #font-family..............font-familyの設定
// #innerWidth...............インナーの幅の設定
// #breakpoints..............breakpointの設定
//

// #color
$colorText: #222; // テキストカラー
$colorMain: #005DBA; // メインカラー
$colorSub: #42B3D6; // サブカラー
$colorAccent: #F66720; // アクセントカラー
$colorAccent2: #FFE257; // アクセントカラー2

// #font-family
// ベースフォント - YakuHanJPs ゴシック体（カッコのみ）を最優先、次にNoto Sans JP
$fontBase: YakuHanJPs_Noto, "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
// Roboto指定
$fontRobot: "Roboto", "Helvetica Neue",Arial,"Hiragino Kaku Gothic Pro","Hiragino San",Meiryo,sans-serif;

// #innerWidth
$innerWidthPc: 992px; // PC表示のインナー幅
$innerWidthPcShort: 928px; // PC表示のインナー幅

// デバイス幅
$breakpoints: (
  "mini-pc": 1140px,
  "large-tablet": 1000px,
  "kindlefireHD": 800px,
  "iPadPro11": 834px,
  "normal-tablet": 768px,
  "tablet": 834px,
  "mini-tablet": 540px,
  "iPhone11": 414px,
  "iPhone12Pro": 390px,
  "iPhone12ProMax": 428px,
  "iPhoneX": 375px,
  "iPhoneXR": 414px,
  "iPhoneSE": 320px,
  "mini-sp": 375px,
);

$mini-pc: 1140px;
$large-tablet: 1000px;
$kindlefireHD: 800px;
$normal-tablet: 768px;
$mini-tablet: 540px;
$iPhone11: 414px;
$iPhoneX: 375px;
$iPhoneSE: 320px;
