/**
 * Global
 * Function.............プロジェクト全体を通して使用される関数です。
 * Media-query..........メディアクエリ用のmixinです。
 * Mixins...............プロジェクト全体を通して使用されるmixinです。
 * Variables............プロジェクト全体を通して使用される変数です。
 *
 * Fundation
 * Base.................要素セレクタと属性セレクタのデフォルトスタイルです。
 * Reset................
 *
 * LAYOUT
 * Header...............ヘッダーエリアのコンテナブロックのスタイルを指定します。
 * Footer...............フッターエリアのコンテナブロックのスタイルを指定します。
 * Pages................各ページのブロックのスタイルを指定します。
 *   Top..................トップページのスタイルを指定します。
 *   Security..............NP光セキュリティページのスタイルを指定します。
 *   Hikari-box............NP光BOXページのスタイルを指定します。
 *   Warrenty..............NP光保証サービスページのスタイルを指定します。
 *   Data-restore..........NPデータ復旧安心サービスページのスタイルを指定します。
 *   Fukuriko..............NP光フクリコページのスタイルを指定します。
 *   Company...............会社概要ページのスタイルを指定します。
 *   Contact...............お問合せページのスタイルを指定します。
 *   Privacy-policy........プライバシーポリシーページのスタイルを指定します。
 *   Others................その他のページのスタイルを指定します。
 *
 * Object
 * Boxes................ボックスのコンポーネントです。
 * Buttons..............ボタンのコンポーネントです。
 * Titles...............タイトルのコンポーネントです。
 * Texts................文字列のコンポーネントです。
 * Tab..................タブのコンポーネントです。
 *
 * UTILITY
 * Index................ページ全体で使用するmarginやtext-alignを調整するためのヘルパークラスです。
 */
@use "Global" as *;
@use "Fundation" as *;
@use "Layout" as *;
@use "Object" as *;
@use "Utility" as *;