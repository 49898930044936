@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Company
   ========================================================================== */

// 会社概要ページのレイアウト
//
// Company-box-01.............ボックス1
//
.title-area {
  +.company-box-01 {
    margin-top: 7.25rem;
  }
}

// #Company-box-01
.company-box-01 {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;

  &:last-of-type {
    margin-bottom: 18.1rem;

    @include breakpoint('normal-tablet') {
      margin-bottom: 6.6rem;
    }
  }

  +.company-box-01 {
    margin-top: 6.6rem;

    @include breakpoint('normal-tablet') {
      margin-top: 8rem;
    }
  }

  .box-wrap {
    @include inner-01;
  }
}

.company-title-02 {
  color: $colorMain;
  font-weight: bold;
  font-size: 2.4rem;
  text-align: center;
  width: 100%;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 0.6rem 0;

  @include breakpoint('normal-tablet') {
    font-size: 1.8rem;
    padding: .8rem 0;
  }

  +.company-table-01 {
    margin-top: 2.95rem;

    @include breakpoint('normal-tablet') {
      margin-top: 3.1rem;
    }
  }
}

.company-table-01 {
  border: 1px solid #458ACD;
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: #458ACD;
    color: #fff;
    font-weight: bold;
    font-size: 1.8rem;
    padding: 1.2rem 2.2rem;
    text-align: left;
    white-space: nowrap;

    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
      padding: 1.2rem 1rem 1.2rem .8rem;
    }
  }

  td {
    background-color: #EEEEEE;
    padding: 1.2rem 2.2rem;

    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
      padding: 1.2rem .8rem;
    }
  }

  tr:not(:last-child) th {
    border-bottom: 1px solid #fff;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid #458ACD;
  }
}