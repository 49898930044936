@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Hikari-box
   ========================================================================== */

// NP光BOXページのレイアウト
//
// Hikari-box-01.........1つ目のボックス(NP光BOXとは？)のスタイル
// Hikari-box-02.........2つ目のボックス(NP光BOXの利用シーン)のスタイル
//

// #Hikari-box-01
.hikari-box-01 {
  padding: 7.6rem 0 8rem;

  .box-wrap {
    @include inner-01($type: 'short');
  }

  .title-03 {
    +.text-01 {
      padding-top: 5.6rem;
    }
  }

  .text-01 {
    text-align: center;

    +.hikari-box-01-main {
      margin-top: 3.4rem;

      @include breakpoint('normal-tablet') {
        margin-top: 4.5rem;
      }
    }
  }

  .hikari-box-01-main {
    border-radius: 6px;
    padding: 3.2rem 0 2.3rem;
    display: flex;
    flex-direction: column;
    @include DefaultShadow1;

    +.hikari-box-01-pie-chart {
      padding-top: 4.35rem;
    }

    @include breakpoint('normal-tablet') {
      padding: 2.2rem .1rem;
    }
  }

  .hikari-box-01-title-02 {
    order: 2;
    color: $colorMain;
    font-size: 2.4rem;
    margin-top: 3rem;
    font-weight: bold;
    line-height: calc(32 / 24);
    text-align: center;

    @include breakpoint('normal-tablet') {
      color: $colorMain;
      font-size: 1.8rem;
      line-height: calc(28 / 18);
      margin-top: 1.6rem;
      padding: 0 7px;
    }
  }

  .hikari-box-01-diagrams {
    order: 1;
    display: grid;
    column-gap: 30px;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    @include breakpoint('normal-tablet') {
      column-gap: 2px;
      max-width: 100%;
    }
  }

  .hikari-box-01-diagrams-item {
    display: flex;
    flex-direction: column;

    &:first-child {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      img {
        width: 193px;

        @include breakpoint('normal-tablet') {
          width: 135px;
        }
      }
    }

    &:nth-child(2) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      position: relative;
      bottom: -35px;

      @include breakpoint('normal-tablet') {
        bottom: auto;
      }

      img {
        width: 160px;

        @include breakpoint('normal-tablet') {
          width: 130px;
        }
      }
    }

    &:nth-child(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;

      img {
        width: 192px;

        @include breakpoint('normal-tablet') {
          width: 135px;
        }
      }
    }

    figure {
      text-align: center;
      order: 1;

      img {
        margin: 0 auto;
      }

      +.hikari-box-01-diagrams-text {
        margin-top: .9rem;
        height: 100%;
      }
    }
  }

  .hikari-box-01-diagrams-title {
    font-size: 2.4rem;
    color: #458ACD;
    text-align: center;
    font-weight: bold;
    margin-top: auto;
    order: 2;

    @include breakpoint('normal-tablet') {
      color: $colorMain;
      font-size: 1.6rem;
      line-height: calc(20 / 16);
      margin-top: 3px;
    }
  }

  .hikari-box-01-diagrams-text {
    text-align: center;
    background-color: #E9F6FE;
    padding: 1.6rem 0 1.1rem;
    line-height: 1.3;
    order: 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint('normal-tablet') {
      padding: .8rem .7r  em 1.6rem;
    }

    >span {
      display: block;
      &:first-child {
        font-size: 1.4rem;
  
        @include breakpoint('normal-tablet') {
          font-size: 1.2rem;
        }
      }
      &:last-child {
        font-size: 1.8rem;
        margin-top: auto;
        flex: 1;
        display: flex;
        align-items: center;
        @include breakpoint('normal-tablet') {
          font-size: 1.4rem;
        }
      }
    }
  }

  .hikari-box-01-pie-chart {
    column-gap: 3.2rem;

    @include breakpoint('normal-tablet') {
      row-gap: 5.6rem;
    }

    .pie-chart-img {
      width: 300px;
      height: auto;
      margin: 2rem auto 0;
    }

    +.hikari-box-01-result {
      margin-top: 4.5rem;
    }
  }

  .hikari-box-01-question {
    font-size: 2.4rem;
    text-align: center;
    padding: 1.25rem 0;
    border-top: 1px solid rgba(112, 112, 112, .4);
    border-bottom: 1px solid rgba(112, 112, 112, .4);

    @include breakpoint('normal-tablet') {
      font-size: 1.8rem;
      padding: .8rem 0;
    }
  }

  .hikari-box-01-result {
    text-align: center;

    >span {
      color: $colorMain;
      font-size: 2.4rem;
      text-align: center;
      padding: .4rem 0;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;

      @include breakpoint('normal-tablet') {
        font-size: 2.2rem;
        padding: .8rem 0;
        display: block;
        width: 100%;
      }
    }

    +.hikari-box-01-text-01 {
      margin-top: 1.15rem;

      @include breakpoint('normal-tablet') {
        margin-top: 1.6rem;
      }
    }
  }

  .hikari-box-01-text-01 {
    font-size: 2rem;
    text-align: center;
    line-height: 1.6;

    @include breakpoint('normal-tablet') {
      font-size: 1.5rem;
    }
  }
}

// #Hikari-box-02
.hikari-box-02 {
  padding: 7.2rem 0 11.6rem;

  .box-wrap {
    @include inner-01;
  }

  .hikari-box-case-list {
    background: #fff;
    border-top: 1px solid #005DBA;
    border-left: 1px solid #005DBA;
    border-right: 1px solid #005DBA;
    max-width: 994px;
    margin: 6.1rem auto 0;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint('normal-tablet') {
      flex-direction: column;
      border-bottom: none;
    }
  }

  .hikari-box-case-item {
    flex-basis: 50%;
    padding-top: 29px;
    background: #fff;
    border-bottom: 1px solid #005DBA;
    padding-left: 45.5px;
    padding-right: 8px;


    @include breakpoint('large-tablet') {
    padding-top: vw(29px, $large-tablet);
    padding-left: vw(45.5px, $large-tablet);
    }

    @include breakpoint('normal-tablet') {
      padding-top: 18px;
      padding-left: 14px;
      padding-right: 14px;
    }

    &:nth-child(odd) {
      border-right: 1px solid #005DBA;

      @include breakpoint('normal-tablet') {
        border-right: none;
      }
    }
  }

  .hikari-box-case-title {
    display: flex;
    align-items: center;
    column-gap: 55px;

    @include breakpoint('normal-tablet') {
      column-gap: 25px;
    }
  }

  .hikari-box-case-title-case {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 2px;
      height: 70px;
      background-color: #005DBA;
      top: 50%;
      right: -28px;
      transform: translateY(-50%) rotate(24deg);

      @include breakpoint('normal-tablet') {
        top: auto;
        right: -12px;
        bottom: 5px;
        height: 44px;
        transform: rotate(19deg);
      }
    }
  }

  .hikari-box-case-title-case-text {
    font-size: 1.4rem;
    font-family: $fontRobot;
    font-weight: bold;
    line-height: 1;

    @include breakpoint('normal-tablet') {
      font-size: 1.2rem;
    }
  }

  .hikari-box-case-title-case-num {
    color: $colorMain;
    font-size: 6.4rem;
    font-family: $fontRobot;
    line-height: 1;

    @include breakpoint('normal-tablet') {
      font-size: 4rem;
    }
  }

  .hikari-box-case-title-text {
    color: $colorMain;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(32 / 24);

    @include breakpoint('normal-tablet') {
      font-size: 2rem;
    }
  }

  .hikari-case-1 {
    padding-bottom: 32px;
    padding-right: 23px;
    padding-left: 46px;

    @include breakpoint('large-tablet') {
      padding-bottom: vw(32px, $large-tablet);
      padding-right: vw(23px, $large-tablet);
      padding-left: vw(46px, $large-tablet);
    }

    @include breakpoint('normal-tablet') {
      padding: 18px 14px 27px;
    }

    .hikari-box-case-content {
      margin-top: 12.5px;
    }

    .case-1-box-plus {
      font-size: 1.8rem;
      font-weight: bold;

      @include breakpoint('normal-tablet') {
        font-size: 1.2rem;
        padding: 0 4px;
      }
    }

    .box-column-02 {
      flex-direction: row;
    }

    .box-column-02-item {
      flex: auto;
      display: flex;
      flex-direction: column;

      @include breakpoint('normal-tablet') {
        flex: 1;
      }

      figcaption {
        @include breakpoint('large-tablet') {
          font-size: vw(16px, $large-tablet);
        }
      @include breakpoint('normal-tablet') {
        font-size: 1.2rem;
      }
      }

      &:first-child {
        flex: 1;
        padding-right: 8px;

        figure {
          margin-top: 35px;

          @include breakpoint('normal-tablet') {
            margin-top: 31px;
          }

          figcaption {
            &:after {
              border-bottom: 4px solid #707070;
              border-left: 4px solid #707070;
              @include breakpoint('normal-tablet') {
                border-left-width: 3px;
                border-bottom-width: 3px;
              }
            }
          }
        }

        img {
          max-width: 100%;
          margin: 0 auto;
          padding: 0 2%;
          @include breakpoint('normal-tablet') {
            padding: 0 18%;
          }
          @include breakpoint('iPhone11') {
          padding: 0 2%;
        }
        }
      }

      &:nth-child(3) {
        width: 85%;
        flex: 1;
        padding-left: 8px;
        figure {
          margin-top: 19px;

          @include breakpoint('normal-tablet') {
            margin-top: 30px;
          }

          figcaption {
            &:after {
              border-bottom: 4px solid #F66720;
              border-left: 4px solid #F66720;
              @include breakpoint('normal-tablet') {
                border-left-width: 3px;
                border-bottom-width: 3px;
              }
            }
          }
        }

        img {
          margin: 0 auto 16px;
          max-width: 100%;
          padding: 0 18%;
          @include breakpoint('normal-tablet') {
          padding: 0 30%;
          }
          @include breakpoint('iPhone11') {
          padding: 0 18%;
        }
        }
      }

      figure {
        flex: auto;
        display: flex;
        flex-direction: column;
      }

      figcaption {
        margin-top: auto;
        padding-left: 26px;
        position: relative;
        width: calc(100% + 8px);

        &:before,
        &:after {
          content: '';
          position: absolute;
        }

        &:before {
          width: 20px;
          height: 20px;
          border: 3px solid #458ACD;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        @include breakpoint('normal-tablet') {
          width: 15px;
          height: 15px;
        }
        }

        &:after {
          width: 23px;
          height: 9px;
          left: 3px;
          top: calc(50% - 10px);
          transform: translateY(-50%);
          transform: rotate(-45deg);
        @include breakpoint('normal-tablet') {
          top: calc(50% - 6px);
          width: 16px;
          height: 6.3px;
        }
        }

        @include breakpoint('normal-tablet') {
          font-size: 1.2rem;
          padding-left: 16px;
        }
      }
    }
  }

  .case-01-lead {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding: 3px 0;
    background: #E9F6FE;
    border: 2px solid #3D7FC3;
    @include breakpoint('large-tablet') {
      font-size: vw(18px, $large-tablet);
    }

    @include breakpoint('normal-tablet') {
      font-size: 1.3rem;
      padding: 2px 0;
    }
  }


  .hikari-case-2 {

    @include breakpoint('normal-tablet') {
      padding-right: 12px;
      padding-bottom: 20px;
    }

    .hikari-box-case-content {
      margin-top: 12.5px;
      img {
        width: 95%;
        max-width: 421px;
        @include breakpoint('normal-tablet') {
        margin: 0 auto;
      }
      }
    }
  }

  .hikari-case-3 {
    padding-bottom: 32px;
    @include breakpoint('large-tablet') {
      padding-bottom: vw(32px, $large-tablet);
    }
    @include breakpoint('normal-tablet') {
      padding-bottom: 32px;
    }
    img {
      width: auto;
      max-width: 238px;
      margin: 0 auto;

    }
  }

  .hikari-case-4 {
    @include breakpoint('normal-tablet') {
      padding-bottom: 2rem;
    }

    .hikari-box-case-content {
      margin-top: 1rem;
      img {
        max-width: 213px;
        width: 42.943%;
        margin: 0 auto;
        @include breakpoint('normal-tablet') {
          width: auto;
        }
      }
    }

    .case-4-lead {
      text-align: center;
      font-size: 1.8rem;
      margin-top: 23px;
      
      @include breakpoint('large-tablet') {
        font-size: 1.5rem;
      }

      @include breakpoint('normal-tablet') {
        margin-top: 28px;
      }

      >span {
        padding: 2px 0;
        font-weight: bold;
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
      }
    }
  }

  .hikari-case-5,
  .hikari-case-6,
  .hikari-case-7,
  .hikari-case-8 {
    padding-bottom: 28px;
    @include breakpoint('large-tablet') {
      padding-bottom: vw(28px, $large-tablet);
    }
    @include breakpoint('normal-tablet') {
      padding-bottom: 28px;
    }

    .hikari-box-case-content {
      font-size: 1.8rem;
      padding-left: 92px;
      line-height: calc(32 / 18);

      @include breakpoint('large-tablet') {
        font-size: 1.5rem;
      }

      @include breakpoint('normal-tablet') {
        line-height: 1.6;
        margin-top: 1rem;
        padding-left: 0;
      }
    }
  }
}