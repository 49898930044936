@use "sass:math";
@use "sass:map";
@use 'variables' as *;

// ==========================================================================
// #Media-query
// ==========================================================================

//
// #breakpoint
//
// @desc - ブレイクポイントを挿入します。
// @type Mixin
// @param {String} $device - `$breakpoints`のkeyを渡します。
// @requires {Map} $breakpoints - ブレイクポイントを管理するためのmapです。
// @error - $deviceの値が$breakpointsで指定のないものだった場合に、メッセージと正しい$deviceの一覧を表示する。
//
// @example scss - Usage
// .foo {
//     color: red;
//     @include breakpoint("tablet") {
//         color: blue;
//     }
// }
//
// @example css - CSS output
// .foo {
//   color: red;
// }
// @media screen and (max-width: 834px) {
//   .foo {
//     color: blue;
//   }
// }
//
@mixin breakpoint($device: "tablet") {
  @if map-has-key($breakpoints, $device) {
    @media screen and (max-width: map.get($breakpoints, $device)) {
      @content;
    }
  }
  @else {
    @error "指定されたブレークポイントは定義されていません。" + "指定できるブレークポイントは次のとおりです。 -> #{map-keys($breakpoints)}";
  }
}