@use 'Global' as *;

/* ==========================================================================
    #Tab
   ========================================================================== */

//
// Box-tabarea-01.........タブ切り替えエリアのスタイル
//

// #Box-tabarea-01
.box-tabarea-01 {
  width: 100%;
  max-width: $innerWidthPcShort;
  margin: 0 auto;
  padding-top: 7.2rem;

  @include breakpoint('normal-tablet') {
    max-width: unset;
    padding-top: 2.3rem;
  }

  [role=tablist] {
    display: flex;
    justify-content: space-between;
    column-gap: 3.2rem;

    @include breakpoint('normal-tablet') {
      padding: 0 16px 0 13px;
      column-gap: 1.6rem;
    }
  }

  [role=tab] {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    border: 1px solid $colorMain;
    background: $colorMain;
    color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1.8rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: color .5s ease-out, background-color .5s ease-out;

    @include breakpoint('normal-tablet') {
      font-size: 1.6rem;
    }

    &:hover {
      background: #e9f6fe;
      color: $colorMain;

      span {
        &:after {
          transform: translateY(-50%) rotate(180deg);
          background: url('../img/common/arrow-up.svg') no-repeat center / 13px 14px;
          width: 14px;
          height: 13px;
        }
      }
    }

    // タブを選択した時
    &[aria-selected=true] {
      background: #e9f6fe;
      color: $colorMain;

      // 選択時の三角
      &:before {
        content: '';
        @include triangle('downward', 17.5px, 10.5px, #035DBA);
        position: absolute;
        top: calc(100% + 1px);
        right: 50%;
        transform: translateX(50%);
      }

      &:focus {
        &:before {
          top: calc(100% + 2px);
        }
      }

      &:after {
        content: '';
        @include triangle('downward', 17px, 10px, #E9F6FE);
        position: absolute;
        top: calc(100% - 1px);
        right: 50%;
        transform: translateX(50%);
      }

      span {
        &:after {
          transform: translateY(-50%) rotate(180deg);
          background: url('../img/common/arrow-up.svg') no-repeat center / 13px 14px;
          width: 14px;
          height: 13px;
        }
      }
    }

    // タブにフォーカスしている時
    &:where(:focus, :active) {
      outline: 1px solid $colorMain;
    }

    span {
      display: block;
      width: 100%;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        background: url('../img/common/arrow-right.svg') no-repeat center / 14px 13px;
        width: 13px;
        height: 14px;

        @include breakpoint('normal-tablet') {
          content: none;
        }
      }
    }
  }

  [role=tabpanel] {
    display: block;
    padding: 32px 64px;
    background-color: #e9f6fe;
    width: 100%;

    @include breakpoint('normal-tablet') {
      padding: 1.6rem;
    }

    &[aria-hidden=true] {
      display: none;
    }
  }

  .box-tabarea-01-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    &-title {
      color: #fff;
      font-size: 2rem;
      background: rgba(0, 93, 186, .7);
      font-weight: bold;
      padding: 9px 16px;

      @include breakpoint('normal-tablet') {
        font-size: 1.8rem;
        line-height: calc(26 / 18);
      }
    }

    &-cont {
      background: #fff;
      padding: 1.2rem 1.6rem;
      font-size: 1.4rem;
    }
  }
}