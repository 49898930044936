@use 'Global' as *;

/* ==========================================================================
    #Boxes
   ========================================================================== */

//
// Box-01.................boxのスタイル。
// Box-column-02..........2つの要素をPCで横並びにするカラム。tablet以下では縦並び(flex-direction-column)になる。
// Box-column-03..........３つの要素をPCで横並びにするカラム。tablet以下では要確認。
// Box-background-fixed...背景の画像がスクロール時に固定される。
//

// #Box-01
.box-01 {
  width: 100%;
  background: #fff;
  position: relative;

  &.box-color-01 {
    background: #E9F6FE;
  }
}

// #Box-column-02
.box-column-02 {
  display: flex;
  justify-content: space-between;
  @include breakpoint('normal-tablet') {
    flex-direction: column;
  }
  .box-column-02-item {
    flex: 1;
  }
}

// #Box-column-03
.box-column-03 {
  display: flex;
  justify-content: space-between;
  @include breakpoint('normal-tablet') {
    flex-direction: column;
  }
  .box-column-03-item {
    flex: 1;
  }
}

// #Box-background-fixed
.box-background-fixed {
  background: rgba(233, 246, 254, .6) url('../img/top/img-background.png') repeat-y top center;
  background-size: cover;
  background-attachment: fixed;
}

/* safariのみ */
_:lang(x)+_:-webkit-full-screen-document,.box-background-fixed {
  background-attachment: unset;
}