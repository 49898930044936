@use 'Global' as *;

/* ==========================================================================
    #buttons
   ========================================================================== */

//
// Button-to-top...........トップに戻るボタン
// Button-01...............ボタン01-ホバーするとボタン色反転のうえ下に押し込まれる
//

// #Button-to-top
// トップに戻るボタン
.button-to-top {
  border: none;
  background: #fff url('../img/common/arrow-up.svg') no-repeat center / 13px 14px;
  box-shadow: 0 0 12px rgb(0 0 0 / 8%);
  border-radius: 6px;
  width: 40px;
  height: 40px;
  position: fixed;
  right: 40px;
  bottom: 43px;
  z-index: 10;
  transition: all .2s ease-out;
  @include breakpoint('normal-tablet') {
    right: 20px;
    bottom: 20px;
  }
  &:hover {
    transform: translateY(-5px);
  }
}

// #Button-01
.btn-01 {
  display: block;
  appearance: none;
  width: 69.5%;
  margin: 0 auto;
  position: relative;
  border: none;
  @include breakpoint('normal-tablet') {
    width: 80.813%;
  }

  &:before {
    position: absolute;
    content: '';
    top: 3px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #003369;
    border-radius: 4px;
    z-index: 0;
  }

  &:hover {
    span {
      top: 3px;
      background: #E9F6FE url("../img/common/arrow-right-02.svg") no-repeat right 21px center/13px 14px;
      border: 1px solid #035DBA;
      color: #035DBA;
    }
  }

  span {
    color: #fff;
    border: 1px solid transparent;
    display: block;
    width: 100%;
    height: 100%;
    background: #005DBA url("../img/common/arrow-right.svg") no-repeat right 21px center/13px 14px;
    padding: .8rem;
    display: inline-flex;
    transition: top .3s ease-out, background-color .3s ease-out, color .3s ease-out;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    transition: top .3s ease-out, background .3s ease-out, color .3s ease-out;
  }
}