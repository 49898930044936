@use "sass:math";
@use 'variables' as *;
@use 'media-query' as *;

// ==========================================================================
// #Minxins
// ==========================================================================

//
// Line-clamp..............breakpointの設定
// Triangle..............三角形の作成
// Inner-01..............内側の最大幅を指定し中央寄せにする
// DefaultShadow1........デフォルトで使用するボックスシャドウ
//

// #Line-clamp
//  表示行数の制御 
@mixin line-clamp($count: 3) {
  // 引数が数値以外だったらエラーを返す
  @if type-of($count) != 'number' {
    @error 'line-clampの引数は必ず数値で指定してください';
  }

  @if $count == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//
// #Triangle
// 
// 三角形の作成
//  第1引数に方向を指定。上向き(upward)、右向き(rightward)、下向き(downward)、左向き(leftward)、左上(upper-left)、右上(upper-right)、右下(lower-right)、左下(lower-left)の８方向から選択します
//  第2引数に三角形の横幅を指定します
//  第3引数に三角形の縦幅を指定します
//  第4引数に三角形の色を指定します。デフォルトは現在の文字色(currentColor)
//
@mixin triangle($direction, $width, $height, $color: currentColor) {
  // 定義されている方向が指定されている、もしくは方向が指定されていなかったらエラーを返す
  @if not index(upward rightward downward leftward upper-left upper-right lower-right lower-left, $direction) {
    @error 'triangle()の方向は"upward","rightward","downward","leftward","upper-left","upper-right","lower-right","lower-left"から指定してください';
  }

  border-style: solid;
  height: 0;
  width: 0;

  @if $direction == upward {
    border-color: transparent transparent $color transparent;
    border-width: 0 math.div($width, 2) $height math.div($width, 2);
  }

  @if $direction == rightward {
    border-color: transparent transparent transparent $color;
    border-width: math.div($height, 2) 0 math.div($height, 2) $width;
  }

  @if $direction == downward {
    border-color: $color transparent transparent transparent;
    border-width: $height math.div($width, 2) 0 math.div($width, 2);
  }

  @if $direction == leftward {
    border-color: transparent $color transparent transparent;
    border-width: math.div($height, 2) $width math.div($height, 2) 0;
  }

  @if $direction == upper-left {
    border-color: $color transparent transparent transparent;
    border-width: $height $height 0 0;
  }

  @if $direction == upper-right {
    border-color: transparent $color transparent transparent;
    border-width: 0 $height $height 0;
  }

  @if $direction == lower-right {
    border-color: transparent transparent $color transparent;
    border-width: 0 0 $height $height;
  }

  @if $direction == lower-left {
    border-color: transparent transparent transparent $color;
    border-width: $height 0 0 $height;
  }
}

// #Inner-01
//  要素の内側の最大幅を$innerWidthPC(992px)にし、中央寄せ
@mixin inner-01($type: medium) {
  margin: 0 auto;
  @if ($type == 'medium') {
    max-width: $innerWidthPc;
    @media screen and (max-width: $innerWidthPc) {
      max-width: calc(100% - 30px);
    }
  }
  @else if($type == 'short') {
    max-width: $innerWidthPcShort;
    @media screen and (max-width: $innerWidthPcShort) {
      max-width: calc(100% - 30px);
    }
  }
  @include breakpoint('normal-tablet') {
    max-width: calc(100% - 30px);
  }
}

// #DefaultShadow1
@mixin DefaultShadow1{
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.08);
}