@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Contact
   ========================================================================== */

// お問合せページのレイアウト
//
// Contact-box-01.............1つ目のボックス
// Contact-box-02.............2つ目のボックス
//

.title-area {
  +.contact-box-01 {
    margin-top: 7.25rem;
  }
}

// #Contact-box-01
.contact-box-01 {
  padding-bottom: 8rem;

  @include breakpoint('normal-tablet') {
    padding-bottom: 6.4rem;
  }
}

.contact-address-01 {
  display: flex;
  justify-content: flex-start;
  border: 1px solid #3D7FC3;
  width: 100%;
  max-width: 608px;
  margin: 4.5rem auto 0;

  @include breakpoint('normal-tablet') {
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 30px);
    margin-top: 3.7rem;
  }

  dt {
    font-weight: bold;
    color: $colorMain;
    font-size: 2rem;
    background-color: #E9F6FE;
    display: flex;
    align-items: center;
    padding: 14px 21px 14px 32px;

    @include breakpoint('normal-tablet') {
      font-size: 1.8rem;
      line-height: calc(32 / 18);
      justify-content: center;
      padding: 0.7rem 0;
    }
  }

  dd {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 14px 21px 14px 32px;

    @include breakpoint('normal-tablet') {
      padding: 1rem 0;
    }

    >a {
      display: block;
      font-size: 2.8rem;
      font-weight: bold;
      line-height: calc(40 / 28);

      @include breakpoint('normal-tablet') {
        font-size: 2.4rem;
        line-height: calc(32 / 24);
      }
    }

    .mini {
      font-size: 1.4rem;
    }
  }
}

// #Contact-box-02
.contact-box-02 {
  padding: 5.4rem 0 12.3rem;

  .title-03 {
    text-align: center;

    +.text-01 {
      margin-top: 3.9rem;
      text-align: center;

      @include breakpoint('normal-tablet') {
        margin-top: 5rem;
        padding: 0 15px;
      }
    }
  }

  .text-caution {
    font-size: 1.3rem;
    text-align: center;
    margin-top: .5rem;

    @include breakpoint('normal-tablet') {
      font-size: 1.2rem;
      padding: 0 15px;
    }
  }

}