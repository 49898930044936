@use 'Global' as *;

/* ==========================================================================
    #Text
   ========================================================================== */

//
// Text-01...............文字列タイプ01
//

// #Text-01
.text-01 {
  font-size: 1.6rem;
  @include breakpoint('normal-tablet') {
    font-size: 1.5rem;
    line-height: calc(24 / 15);
  }
}