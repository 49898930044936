@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Privacy-policy
   ========================================================================== */

// プライバシーポリシーページのレイアウト
//
// Privacy-policy-box-01................
// Privacy-policy-contents-box..........
//

.title-area {
  +.privacy-policy-box-01 {
    margin-top: 7.25rem;

    @include breakpoint('normal-tablet') {
      margin-top: 4.8rem;
    }
  }
}

// #Privacy-policy-box-01
.privacy-policy-box-01 {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;

  @include breakpoint('normal-tablet') {
    padding: 0 15px;
  }

  &:last-of-type {
    margin-bottom: 13rem;

    @include breakpoint('normal-tablet') {
      margin-bottom: 5.7rem;
    }
  }

  +.privacy-policy-box-01 {
    margin-top: 6.25rem;

    @include breakpoint('normal-tablet') {
      margin-top: 4rem;
    }
  }
}

.privacy-policy-ttl-02 {
  color: $colorMain;
  font-weight: bold;
  font-size: 2.4rem;
  padding: .8rem 0;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  line-height: calc(32 / 24);

  @include breakpoint('normal-tablet') {
    font-size: 1.8rem;
    line-height: calc(32 / 18);
  }

  +.privacy-policy-contents {
    margin-top: 3.35rem;

    @include breakpoint('normal-tablet') {
      margin-top: 1.6rem;
    }
  }
}

.privacy-policy-text-01 {
  line-height: 2;

  @include breakpoint('normal-tablet') {
    font-size: 1.5rem;
  }

  +.privacy-policy-list-01 {
    margin-top: 0.8rem;

    @include breakpoint('normal-tablet') {
      margin-top: 2.4rem;
    }
  }

  +.privacy-policy-table-01 {
    margin-top: 4rem;

    @include breakpoint('normal-tablet') {
      margin-top: 3.2rem;
    }
  }
}

.privacy-policy-list-01 {
  background-color: #E9F6FE;
  padding: 1.5rem;
  width: 100%;
  max-width: 1056px;
  margin: 0 auto;

  @include breakpoint('normal-tablet') {
    padding: 1.35rem .5rem 1.5rem 1.1rem;
  }


  .privacy-policy-list-item {
    font-size: 1.5rem;
    display: flex;
    column-gap: 5px;

    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
    }

    .num {
      font-weight: bold;
      font-family: $fontRobot;
      font-size: 1.6rem;

      @include breakpoint('normal-tablet') {
        font-size: 1.4rem;
      }
    }

    +.privacy-policy-list-item {
      margin-top: 1.2rem;

      @include breakpoint('normal-tablet') {
        margin-top: 1rem;
      }
    }
  }
}

.privacy-policy-list-02 {
  padding-bottom: 2rem;

  @include breakpoint('normal-tablet') {
    padding-bottom: 0;
  }
}

.privacy-policy-table-01 {
  width: 100%;
  border: 1px solid #458ACD;
  border-collapse: collapse;

  tr:not(:last-child) th {
    border-bottom: 1px solid #fff;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid #458ACD;
  }

  th {
    background-color: #458ACD;
    color: #fff;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: calc(32 / 18);
    padding: 1rem 2.5rem;
    text-align: left;
    white-space: nowrap;

    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
      padding: 0.8rem;
    }
  }

  td {
    background-color: #E4E4E4;
    vertical-align: middle;
    padding: 1rem 2.5rem;
    font-size: 1.8rem;

    @include breakpoint('normal-tablet') {
      font-size: 1.4rem;
      padding: 0.8rem;

    }
  }

  .large {
    font-weight: bold;
    font-size: 2.4rem;
    margin-right: 1rem;
    font-family: $fontRobot;

    @include breakpoint('normal-tablet') {
      font-size: 1.6rem;
      display: block;
    }
  }

  .small-sp {
    @include breakpoint('normal-tablet') {
      font-size: 1.2rem;
    }
  }
}