@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Data-restore
   ========================================================================== */

// NPデータ復旧安心サービスページのレイアウト
//
// Data-restore-box-01
// Data-restore-box-main.....メインビジュアルのボックス
//

// #Data-restore-box-01
.data-restore-box-01 {
  padding-top: 7.6rem;
  padding-bottom: 11.6rem;

  @include breakpoint('normal-tablet') {
    padding-top: 65px;
    padding-bottom: 57px;
  }

  .box-wrap {
    @include inner-01;
  }

  .text-01 {
    text-align: center;
    margin-top: 5.6rem;

    @include breakpoint('normal-tablet') {
      margin-top: 4.3rem;
    }

    +.data-restore-box-main {
      margin-top: 62.5px;

      @include breakpoint('normal-tablet') {
        margin-top: 22px;
      }
    }
  }
}

// #Data-restore-box-main
.data-restore-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8.444%;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;

  @include breakpoint('normal-tablet') {
    flex-direction: column;
    row-gap: 9px;
    align-items: inherit;
  }
}

.data-restore-figure-01 {
  max-width: 280px;

  @include breakpoint('normal-tablet') {
    max-width: 83.75%;
    margin: 0 auto;
  }
}

.data-restore-point {
  max-width: 544px;
  flex: auto;

  @include breakpoint('normal-tablet') {
    max-width: 100%;
  }
}

.data-restore-point-item {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 23px 0 23px 13px;
  column-gap: 11.58%;

  @include breakpoint('normal-tablet') {
    column-gap: 30px;
    padding-left: 6px 0 6px 2px;
    border-bottom: 1px solid #CCCCCC;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #CCCCCC;
  }

  .point {
    color: #222;
    font-size: 1.4rem;
    font-family: $fontRobot;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1;
    position: relative;

    @include breakpoint('normal-tablet') {
      font-size: 1.2rem;
    }

    &:before {
      position: absolute;
      content: "";
      width: 2px;
      height: 70px;
      background-color: #005DBA;
      top: 0;
      right: -35px;
      transform: rotate(24deg);

      @include breakpoint('normal-tablet') {
        top: auto;
        right: -16px;
        bottom: 7px;
        height: 58px;
        transform: rotate(16deg);
      }
    }

    >span {
      color: $colorMain;
      font-size: 6.4rem;

      @include breakpoint('normal-tablet') {
        font-size: 5.6rem;
      }
    }
  }

  .lead {
    color: $colorMain;
    font-size: 2.4rem;
    line-height: 1.333;

    @include breakpoint('normal-tablet') {
      font-size: 2rem;
      line-height: 1.4;
    }

    .line {
      border-bottom: 2px solid #F35D29;
    }

    .large {
      font-size: 3.2rem;
    }
  }
}

.data-restore-title-04 {
  margin-top: 77px;
  text-align: center;
  line-height: 1.333;

  @include breakpoint('normal-tablet') {
    margin-top: 54px;
  }

  span {
    color: $colorMain;
    padding: 7px 0;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    font-size: 2.4rem;
    font-weight: bold;

    @include breakpoint('normal-tablet') {
      display: block;
    }
  }

  +.data-restore-caution-01 {
    margin-top: 1.375rem;

    @include breakpoint('normal-tablet') {
      margin-top: .975rem;
    }
  }
}

.data-restore-caution-01 {
  font-size: 1.3rem;
  text-align: center;

  @include breakpoint('normal-tablet') {
    font-size: 1.2rem;
  }
}

.data-restore-feature {
  display: flex;
  justify-content: center;
  column-gap: 46px;
  margin-top: 4.7rem;

  @include breakpoint('normal-tablet') {
    flex-direction: column;
    row-gap: 3.2rem;
    margin-top: 2.5rem;
  }
}

.data-restore-feature-item {
  flex: 1;
  max-width: 300px;
  border-radius: 6px;
  @include DefaultShadow1;

  @include breakpoint('normal-tablet') {
    width: 100%;
    max-width: 100%;
  }
}

.data-restore-feature-title {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: $colorMain;
  text-align: center;
  padding: 8px 0 11px;
  color: #fff;
  font-size: 2rem;

  .mini {
    font-size: 1.6rem;
    font-weight: normal;
  }

  .line {
    border-bottom: 2px solid $colorAccent2;
  }
}

.data-restore-feature-content {
  padding: 14px 16px 22px;
}