@use 'Global' as *;

/* ==========================================================================
    #Utility
   ========================================================================== */ 
// 調整用クラス

//
// U-text-center...........文字をセンター寄せにする
// U-font-robot............Robotフォントを指定
// U-text-bold.............文字を太字(font-weight:bold)にする
// U-only-mini.............スマートフォン（normal-tablet以下）以下の場合のみ表示する
// U-only-medium...........画面幅PC（normal-tablet以下）以上の場合のみ表示する
// U-only-medium-inline....画面幅PC（normal-tablet以下）以上の場合のみ表示し、表示の際はinline要素にする
// U-sub-1.................添え字の指定。font-sizeを12pxに指定する。
// U-sub-2.................添え字の指定。font-sizeを16pxに指定する。
//

// #U-text-center
.u-text-center {
  text-align: center;
  @include breakpoint('normal-tablet') {
    text-align: left;
  }
}

// #U-font-robot
.u-font-robot {
  font-family: $fontRobot;
}

// #U-text-bold
.u-text-bold {
  font-weight: bold;
}

// #U-only-mini
.u-only-mini {
  display: none;

  @include breakpoint('normal-tablet') {
    display: block;
  }
}

// #U-only-medium
.u-only-medium {
  display: block;

  @include breakpoint('normal-tablet') {
    display: none;
  }
}

.u-only-medium-inline {
  display: inline;

  @include breakpoint('normal-tablet') {
    display: none;
  }
}

// #U-sub-1
.u-sub-1 {
  font-size: 1.2rem;
}

// #U-sub-2
.u-sub-2 {
  font-size: 1.6rem;
}