@use "sass:math";
@use 'Global' as *;

/* ==========================================================================
    #Warrenty
   ========================================================================== */

// NP光保証サービスページのレイアウト
//
// Warrenty-box-01................
// Warrenty-contents-box..........
//

// #Warrenty-box-01
.warrenty-box-01 {
  padding-top: 7.6rem;
  padding-bottom: 12.3rem;

  @include breakpoint('normal-tablet') {
    padding-top: 6.5rem;
    padding-bottom: 0;
  }

  .text-01 {
    text-align: center;
    margin-top: 56px;

    +.warrenty-contents-box {
      margin-top: 7.775rem;

      @include breakpoint('normal-tablet') {
        margin-top: 3.6rem;
      }
    }
  }
}

// #Warrenty-contents-box
.warrenty-contents-box {
  width: 100%;
  max-width: 992px;
  margin: 0 auto;
}

.warrenty-title-04 {
  width: 100%;
  background-color: $colorMain;
  color: #fff;
  font-weight: bold;
  font-size: 2.4rem;
  padding: 0.8rem 0;
  text-align: center;
}

.warrenty-detail {
  width: 100%;
  background-color: #E9F6FE;
  padding: 25px 11px 21px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;

  @include breakpoint('normal-tablet') {
    flex-direction: column;
    padding: 0 15px 5.6rem;
    align-items: stretch;
  }
}

.warrenty-detail-figure {
  max-width: 280px;
  align-self: center;
}

.warrenty-detail-table {
  display: flex;

  @include breakpoint('normal-tablet') {
    flex-direction: column;
  }
}

.warrenty-detail-table-block {
  display: flex;
  flex-direction: column;

  &:not(:last-child) .warrenty-detail-title {
    border-right: 1px solid #fff;

    @include breakpoint('normal-tablet') {
      border-right: none;
    }
  }

  &:last-child .warrenty-detail-item-wrap {
    border-right: 1px solid $colorMain;

    @include breakpoint('normal-tablet') {
      border-right: none;
    }
  }
}

.warrenty-detail-title {
  background-color: #458ACD;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 1.2rem 1.5rem;

  @include breakpoint('normal-tablet') {
    padding: 6px 11px 10px;
  }
}

.warrenty-detail-item-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include breakpoint('normal-tablet') {
    flex-direction: row;
  }
}

.warrenty-detail-table-item {
  background-color: #fff;
  line-height: 2;
  padding-left: 15px;
  padding-right: 9px;
  flex: 1;
  display: flex;
  align-items: center;
  border-left: 1px solid $colorMain;
  border-bottom: 1px solid $colorMain;

  @include breakpoint('normal-tablet') {
    font-size: 1.3rem;
    line-height: calc(18 / 13);
    border: none;
    align-items: flex-start;
    padding: 5px 10px 20px;
  }
}